
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ENTER, SEMICOLON, FF_SEMICOLON } from "@angular/cdk/keycodes";
import {
    Component, OnInit, ViewChild, ElementRef, Input,
    ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, OnDestroy, Output, EventEmitter
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Utils } from "src/app/common";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { MatAutocompleteTrigger, MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { Store } from "@ngrx/store";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { getZimbraFeatures } from "src/app/reducers";
import { ZimbraFeatures } from "src/app/common/utils/zimbra-features";

@Component({
    selector: "vp-calendar-equipment-autocomplete",
    templateUrl: "./calendar-equipment-autocomplete.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
  })
export class CalendarEquipmentAutoCompleteComponent implements OnInit, OnChanges, OnDestroy {
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = false;
    separatorKeysCodes: number[] = [ENTER];
    emailCtrl = new FormControl();
    filteredEmails: string[];
    emails: any[] = [];
    searchedUsers: any[] = [];
    private isAlive$ = new Subject<boolean>();
    iszimbraFeatureGalEnabled: boolean = false;

    @Input() place: string;
    @Input() id: string;
    @Input() type: string = "Equipment";
    @ViewChild("emailInput", { static: false }) emailInput: ElementRef<HTMLInputElement>;
    @ViewChild("auto", { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;
    @Output() added = new EventEmitter<any>();
    @Output() removed = new EventEmitter<any>();
    conflictEmails = {};
    constructor(
        private changeDetectionRef: ChangeDetectorRef,
        private broadcaster: MailBroadcaster,
        private mailService: MailService,
        private store: Store<CalendarState>
    ) {
        this.emailCtrl.valueChanges.pipe(debounceTime(100)).subscribe(value => {
            if (MailUtils.isJson(value)) {
                return;
            }
            this.loadEmailSuggestion(value);
        });
        this.store.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.iszimbraFeatureGalEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_GAL_ENABLED);
        });
    }

    loadEmailSuggestion(query) {
        if (query === "" || /\s/g.test(query)) {
            this.filteredEmails = [];
            this.changeDetectionRef.markForCheck();
            return;
        }
        if (!this.iszimbraFeatureGalEnabled) {
            return;
        }
        this.mailService.getAutoCompleteGalList(query).subscribe(
            res => {
                this.searchedUsers = [];
                console.log("[getAutoCompleteGalList]", res);
                if (res && res.length > 0) {
                    this.searchedUsers = res.filter(v => v._attrs.zimbraCalResType === this.type).map(v => {
                        return { title: v._attrs.fullName, name: v._attrs.fullName, email: v._attrs.email, image: "", checked: false };
                    });
                } else if (Utils.validateEmail(query)) {
                    this.searchedUsers.push({ title: query, name: query, email: query, image: "", checked: false });
                }

                this._filter(query);
                this.changeDetectionRef.markForCheck();
            },
            err => {
                if (Utils.isJson(err._body)) {
                    // this.mailService.openSnackBar(JSON.parse(err._body).msg);
                }
            }
        );
    }

    ngOnChanges(changes) { }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || "").trim()) {
            if (Utils.validateEmail(value)) {
                if (!this.emails.find(v => v.email === value.trim())) {
                    const item = { title: value, name: value, email: value, image: "", checked: false };
                    this.emails.push(item);
                    this.added.emit(value);
                }
                this.autocompleteTrigger.closePanel();
                this.changeDetectionRef.markForCheck();
            }
        }
        if (input) {
          input.value = "";
        }
        this.emailCtrl.setValue(null);
        this.changeDetectionRef.markForCheck();
    }

    remove(v: any): void {
        const index = this.emails.indexOf(v);
        if (index >= 0) {
            this.emails.splice(index, 1);
            this.removed.emit(v.email);
            this.broadcaster.broadcast("AUTO_COMPLETE_REMOVE_ITEM");
            this.changeDetectionRef.markForCheck();
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        console.log("[selected]", );
        if (!this.emails.find(v => v.email === event.option.value.email)) {
            this.added.emit(event.option.value.email);
            this.emails.push(event.option.value);
        }
        this.emailInput.nativeElement.value = "";
        this.emailCtrl.setValue(null);
        this.broadcaster.broadcast(MailConstants.BROADCAST_OVERFLOW_REDIRECT_DIALOG);
        this.changeDetectionRef.markForCheck();
    }

    private _filter(value: string): void {
        if (value !== null) {
            this.filteredEmails = this.searchedUsers.filter(user => !this.emails.includes(user.email));
            this.changeDetectionRef.markForCheck();
        }
    }

    ngOnInit() { }

    getSelectedEmail(): any[] {
        return this.emails;
    }

    getInputValue(): string {
        return this.emailInput.nativeElement.value;
    }

    setEmailField(emailInfo: any): void {
        if (!this.emails.find(v => v.email === emailInfo.email)) {
            const item = { title: emailInfo.fullName, name: emailInfo.fullName, email: emailInfo.email, image: "", checked: false };
            this.emails.push(item);
            this.added.emit(emailInfo.email);
            this.changeDetectionRef.markForCheck();
        }

    }

    getAvatar(email) {
        return null; // this.convRepository.getAvatar(email);
    }

    resetEmail(): void {
        this.emails = [];
        this.changeDetectionRef.markForCheck();
    }

    resetInputValue(): void {
        this.emailInput.nativeElement.value = "";
        this.changeDetectionRef.markForCheck();
    }

    onInputEvent(ev): void {
        if (typeof cordova !== "undefined") {
            if (ev.data === ";") {
                const input = ev.target;
                const value = ev.target.value.replace(/;/g, "");
                this.addEmailToChips(input, value);
            }
        }
    }

    addEmailToChips(input: any, value: string) {
        if ((value || "").trim()) {
            if (Utils.validateEmail(value)) {
                const item = { title: value, name: value, email: value, image: "", checked: false };
                this.autocompleteTrigger.closePanel();
                this.changeDetectionRef.markForCheck();
            }
        }
        if (input) {
            input.value = "";
        }
        this.emailCtrl.setValue(null);
        this.changeDetectionRef.markForCheck();
    }

    onKeyDown(ev) {
        if (typeof cordova !== "undefined") {
            if (ev.key === "Backspace" && ev.target.value === "") {
                this.emails.pop();
                setTimeout(() => {
                    this.emailInput.nativeElement.focus();
                    this.autocompleteTrigger.closePanel();
                }, 50);
            }
        }
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    setConflictEmails(data) {
        this.conflictEmails = data;
        this.changeDetectionRef.markForCheck();
    }

    isConflict(email) {
        if (typeof this.conflictEmails[email] === "undefined") {
          return false;
        } else {
          return !this.conflictEmails[email];
        }
    }

    setInputBox(str: any): void {
        this.emailInput.nativeElement.value = str;
    }
}
